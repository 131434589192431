  *{padding: 0; margin: 0; border: 0; z-index: 1;}
  
  .intro-top{
    width: 71vw;
    margin-left: 17vw;
    font-size: 5vw;
    font-family: monospace;
    color: rgb(80, 80, 80);
  }
  .intro-name{
    width: 71vw;
    margin-left: 13vw;
    margin-top: 1vh;
    font-size: 7vw;
    font-family: monospace;
    color: rgb(160, 160, 160);
  }
  .introduce{
    width: 70vw;
  }
  .logo-cont{
    width: 10vw;
    height: 10vw;
  }
  .personal-desc{
    z-index: 1;
    width: 47vw;
    height: 30vh;
    margin-left: 14vw;
    margin-top: 1vh;
    font-family: monospace;
    color: rgb(130, 130, 130);
    font-size: 1.7vw;
    line-height: 2.5vw;
  }
  .tech-stack{
    
    position: absolute;
    margin-left: 71vw;
    width: 15vw;
    height: 80vh;
  }
  .moving-image{
    opacity: 30%;
    position: absolute;
    pointer-events: none;
    z-index: -1;
  }
  .rayHelm{
    margin-top: 150vh;
    margin-left: 50vw;
    width: 30vw;
    height: auto;
  }
  .ElemArt{
    margin-top: 50vh;
    margin-left: 10vw;
    width: 25vw;
    height: auto;
  }
  .fGrade{
    margin-top: 100vh;
    margin-left: 5vw;
    width: 30vw;
    height: auto;
  }
  .nameWord{
    margin-top: 170vh;
    margin-left: 60vw;
    width: 25vw;
    height: auto;
  }