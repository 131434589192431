*{padding: 0; border: 0; margin: 0;}
.page{
  width: 99vw;
}
.App{
  height: 300vh;
  width: 99vw;
  background-color: rgb(40, 40, 40);
  padding-top: 13vw;
}

.lim-cont{
  width: 60vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25vh;
}

.exp-cont{
  margin-top: 5vh;
  width: 60vw;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25vh;
}

.exp-title, .lim-title{
  font-weight: bold;
  font-size: 3.5vw;
}

.personal-desc-two{
  z-index: 1;
  width: 43vw;
  height: 30vh;
  font-weight: 600;
  margin-left: 50vw;
  margin-top: 48vh;
  font-family: monospace;
  color: rgb(0, 0, 0);
  font-size: 1.7vw;
  line-height: 2.5vw;
  top: 0%;  /* Adjust as needed */
  left: 0%; /* Adjust as needed */
}
.flex{
  display: flex;
}
.set-absolute{
  position: absolute;
}
.set-fixed{
  position: fixed;
}