.Img{
    position: relative;
    margin: auto;
    z-index: 1;

    user-select: none; /* Standard syntax */
      -webkit-user-select: none; /* Chrome, Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
}

.cont{
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 1;
}