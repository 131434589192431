*{padding: 0; border: 0; margin: 0;}
.stack-img{
    max-height: 80vh;
    margin-bottom: 2vh;
}
.img-select-cont{
    display: flex;
    height: fit-content;
    align-items: center;
    justify-content: center;
}
.total-cont{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.front-button, .back-button{
    width: 5vw;
    height: 5vw;
}
button{
    background-color: transparent;
}
.center-cont{
    position: relative;
    margin: auto;
}
.img-select{
    height: fit-content;
    width: auto;
    height: 100vh;
    display: flex;
    justify-content: center;
}
.img-cont{
    display: flex;
    justify-content: center;
}
.stack-title{
    margin-bottom: 2vh;
    font-size: 2em;
    font-weight: bold;
}
.stack-desc{
    width: 26vw;
}
.front-button{
    margin-right: auto;
}
.selector{
    display: flex;
    justify-content: center;
}
.center{
    display: grid;
    place-items: center;
}
.stack-desc-cont{
    margin: auto;
    font-family: sans-serif;
    text-align: center;
    max-width: 60vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.center-img{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.img{
    width: 20vw;
    margin-bottom: 0vh;
}
@media only screen and (max-width: 992px){
    .img{
        width: 80vw;
    }
    .code-img{
        width: 80vw;
    }
    .title, .stack-desc-cont, .stack-desc{
        width: 80vw;
    }
}
.center-text{
    display: flex;
    align-items: center;
    /* border: 1px solid red; */
} 
.container{
    margin-top: 0vh;
}